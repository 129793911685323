import React, { ChangeEventHandler } from "react";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet-async";
import {
  Container as ContainerMaterial,
  Box,
  Theme,
  SxProps,
  alpha,
  InputBase,
} from "@mui/material";
import LoadingAnimation from "src/assets/json/loading.json";
import AdminAppBar from "src/admin/components/AdminAppBar";
import AdminToolbar from "src/admin/components/AdminToolbar";
import useWindowDimensions from "src/core/utils/dimensions";

type Props = {
  children: React.ReactNode;
  loading?: boolean;
  title?: string;
  toolbar?: React.ReactNode;
  helmetTitle?: string;
  custom?: boolean;
  sx?: SxProps<Theme> | undefined;
  search?: boolean;
  searchOnChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  placeholderSearch?: string;
};

const Container = (props: Props) => {
  const {
    children,
    loading,
    helmetTitle,
    custom,
    sx,
    title,
    toolbar,
    search,
    searchOnChange,
    placeholderSearch,
  } = props;

  const { width, height } = useWindowDimensions();

  if (loading) {
    return (
      <React.Fragment>
        <Helmet>
          <title>{helmetTitle != null ? helmetTitle : "Tecnicentro"}</title>
        </Helmet>

        <AdminAppBar>
          <AdminToolbar title={title}></AdminToolbar>
        </AdminAppBar>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          height={height - 250}
          sx={sx}
        >
          <Lottie
            style={{
              width: 110,
              height: 110,
            }}
            animationData={LoadingAnimation}
            loop={true}
          />
        </Box>
      </React.Fragment>
    );
  }

  if (custom) {
    return (
      <React.Fragment>
        <Helmet>
          <title>{helmetTitle ?? "Tecnicentro"}</title>
        </Helmet>
        <AdminAppBar>
          <AdminToolbar title={title}>{toolbar}</AdminToolbar>
        </AdminAppBar>
        <ContainerMaterial
          style={{
            margin: 20,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          sx={sx}
        >
          {children}
        </ContainerMaterial>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{helmetTitle ?? "Tecnicentro"}</title>
      </Helmet>
      {title ? (
        <AdminAppBar>
          <AdminToolbar
            title={title}
            search={search}
            searchOnChange={searchOnChange}
            placeholderSearch={placeholderSearch}
          >
            {toolbar}
          </AdminToolbar>
        </AdminAppBar>
      ) : undefined}

      <Box
        sx={{
          width: "100%",
          maxWidth:
            width > 1200 ? width - 340 : width > 600 ? width - 95 : width - 50,
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

export default Container;
