import React from "react";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import LoadingAnimation from "src/assets/json/loading.json";
import { Fragment } from "react";
const Loader = () => {
  return (
    <>
      <Helmet>
        <title>Tecnicentro</title>
      </Helmet>
      <Fragment>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Lottie
            style={{
              width: 110,
              height: 110,
            }}
            animationData={LoadingAnimation}
            loop={true}
          />
        </Box>
      </Fragment>
    </>
  );
};

export default Loader;
